import React from "react";
import { Client } from "@twilio/conversations";
import { Button } from "@twilio-paste/button";
import { PlusIcon } from "@twilio-paste/icons/esm/PlusIcon";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators, AppState } from "../../store";
import { getTranslation } from "./../../utils/localUtils";
import { addConversation } from "../../api";

interface NewConvoProps {
  client?: Client;
  collapsed: boolean;
}

const CreateConversationButton: React.FC<NewConvoProps> = (
  props: NewConvoProps
) => {
  const dispatch = useDispatch();
  const { updateCurrentConversation, addNotifications, updateParticipants } =
    bindActionCreators(actionCreators, dispatch);

  const local = useSelector((state: AppState) => state.local);
  const createNewConvo = getTranslation(local, "createNewConvo");

  const handleCreateConversation = async () => {
    const convo = await addConversation(
      "Conversation sans titre",
      updateParticipants,
      props.client,
      addNotifications
    );
    updateCurrentConversation(convo.sid);
  };

  return (
    <Button fullWidth variant="secondary" onClick={handleCreateConversation}>
      <PlusIcon decorative={false} title="Add convo" />
      {!props.collapsed ? createNewConvo : null}
    </Button>
  );
};

export default CreateConversationButton;
